/**
 *
 * @param num
 * @param precision
 * @param separator
 * @returns {*}
 *=======================================================
 *     formatNumber(10000)="10,000"
 *     formatNumber(10000, 2)="10,000.00"
 *     formatNumber(10000.123456, 2)="10,000.12"
 *     formatNumber(10000.123456, 2, ' ')="10 000.12"
 *     formatNumber(.123456, 2, ' ')="0.12"
 *     formatNumber(56., 2, ' ')="56.00"
 *     formatNumber(56., 0, ' ')="56"
 *     formatNumber('56.')="56"
 *     formatNumber('56.a')=NaN
 *=======================================================
 */
function formatNumber (num, precision, separator) {
  var parts;
  // 判断是否为数字
  if (!isNaN(parseFloat(num)) && isFinite(num)) {
    // 把类似 .5, 5. 之类的数据转化成0.5, 5, 为数据精度处理做准, 至于为什么
    // 不在判断中直接写 if (!isNaN(num = parseFloat(num)) && isFinite(num))
    // 是因为parseFloat有一个奇怪的精度问题, 比如 parseFloat(12312312.1234567119)
    // 的值变成了 12312312.123456713
    num = Number(num);
    // 处理小数点位数
    num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
    // 分离数字的小数部分和整数部分
    parts = num.split('.');
    // 整数部分加[separator]分隔, 借用一个著名的正则表达式
    parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));

    return parts.join('.');
  }
  return NaN;
}


/**
 * 把数字转换成货币的格式
 * @param decimals
 * @param dec_point
 * @param thousands_sep
 * @returns {string}
 */
Number.prototype.format = function (decimals, dec_point, thousands_sep) {
  var num = (this + '')
    .replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+num) ? 0 : +num,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k)
        .toFixed(prec);
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '')
    .length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1)
      .join('0');
  }
  return s.join(dec);
}
// 数字补0操作
function addZero (num) {
  return num < 10 ? '0' + num : num;
}

function formatDateTime (date) {
  var time = new Date(Date.parse(date));
  time.setTime(time.setHours(time.getHours() + 8));
  var Y = time.getFullYear() + '.';
  var M = this.addZero(time.getMonth() + 1) + '.';
  var D = this.addZero(time.getDate()) + ' ';
  var h = this.addZero(time.getHours()) + ':';
  var m = this.addZero(time.getMinutes()) + ':';
  var s = this.addZero(time.getSeconds());
  return Y + M + D;
  // }
}


// 时间格式 time=>时间戳；pattern=>': . /'等；type=>type:1=>2019/03/25;type:2=>2019/3/5;type:3=>2019/03/25 03:26;type:4=>2019/3/5 03:26;
function gettime (time, pattern, type) {
  //new Date() 的方法getFullYear、getMonth、getDate、getHours、getMinutes
  var time = Number(time);
  var time = new Date(time)
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  switch (type) {
    case 1:
      return y + pattern + add0(m) + pattern + add0(d)
      break;
    case 2:
      return y + pattern + m + pattern + d
      break;
    case 3:
      return y + pattern + add0(m) + pattern + add0(d) + ' ' + add0(h) + ':' + add0(mm)
      break;
    case 4:
      return y + pattern + m + pattern + d + ' ' + add0(h) + ':' + add0(mm)
      break;
  }
}
// 格式化时间 加0
function add0 (m) {
  return m < 10 ? '0' + m : m
}